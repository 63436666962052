var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full"},[_c('div',{staticClass:"flex-shrink-0 p-4 border-b border-gray-100 flex flex-col gap-4"},[_c('fw-heading',[_vm._v("Versões do documento")]),(_vm.currentVersion?.key)?_c('div',{staticClass:"flex gap-3"},[(_vm.currentVersion.state != 'draft')?_c('fw-button',{staticClass:"flex-1",attrs:{"type":"xlight"},nativeOn:{"click":function($event){return _vm.$emit('snapshot', _vm.currentVersion.key)}}},[_vm._v(" "+_vm._s(_vm.$t('createVersion'))+" ")]):_vm._e(),(_vm.canPublish)?_c('fw-button',{staticClass:"flex-1",attrs:{"type":"primary"},nativeOn:{"click":function($event){return _vm.publish()}}},[_vm._v(" "+_vm._s(_vm.$t('publish'))+" ")]):_vm._e()],1):_vm._e(),(_vm.currentVersion?.key)?[(_vm.currentVersion.state != 'draft' && _vm.currentVersion.pdf_file_status === 'error')?_c('fw-panel-info',{attrs:{"type":"warning"}},[_vm._v(" "+_vm._s(_vm.$t('errorGeneratingPDF'))+" ")]):(_vm.currentVersion.state != 'draft' && _vm.currentVersion.pdf_file?.key)?_c('RecordFileEntry',{key:_vm.currentVersion.pdf_file.key,attrs:{"file":_vm.currentVersion.pdf_file,"can-edit":false,"can-remove":false,"can-preview":false,"minimal":""},on:{"download":function($event){return _vm.downloadFile(_vm.currentVersion.pdf_file)}}}):_vm._e(),(
          _vm.debugMode &&
            _vm.currentVersion.state == 'published' &&
            (_vm.currentVersion.pdf_file_status == 'error' || !_vm.currentVersion.pdf_file_status)
        )?_c('fw-button',{staticClass:"flex-1",attrs:{"type":"xlight"},nativeOn:{"click":function($event){return _vm.$emit('generate-pdf', { pageKey: _vm.page.key, versionKey: _vm.currentVersion.key })}}},[_vm._v(" "+_vm._s(_vm.$t('generatePDF'))+" ")]):_vm._e()]:_vm._e()],2),(!_vm.loading)?_c('div',{staticClass:"px-4 flex flex-col gap-2 pt-4"},_vm._l((_vm.versions),function(version){return _c('RecordVersion',{key:version.key,attrs:{"active":_vm.currentVersion && version.key == _vm.currentVersion.key,"version":version},nativeOn:{"click":function($event){return (() => {
          if (version.key != _vm.currentVersion.key) _vm.changeVersion(version)
        }).apply(null, arguments)}}})}),1):_c('div',{staticClass:"px-4 flex flex-col gap-2"},[_c('div',{staticClass:"bg-gray-100 h-12 rounded-md animate-pulse"}),_c('div',{staticClass:"bg-gray-100 h-12 rounded-md animate-pulse"}),_c('div',{staticClass:"bg-gray-100 h-12 rounded-md animate-pulse"}),_c('div',{staticClass:"bg-gray-100 h-12 rounded-md animate-pulse"}),_c('div',{staticClass:"bg-gray-100 h-12 rounded-md animate-pulse"})])])
}
var staticRenderFns = []

export { render, staticRenderFns }
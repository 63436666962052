<template>
  <div
    class="p-3 items-center bg-white text-sm font-semibold rounded-md flex gap-2 text-gray-500"
    :class="{ 'cursor-pointer hover:bg-gray-100': !active }"
  >
    <div
      class="h-1.5 w-1.5 rounded-full"
      :class="{
        'bg-primary': active,
        'bg-gray-300': !active
      }"
    ></div>
    <div
      class="flex-1 line-clamp-1 overflow-hidden overflow-ellipsis"
      :class="{ 'text-gray-800': !!version?.title?.length }"
    >
      {{ title }}
    </div>
    <fw-tag size="xs" :type="statesLabes[version.state].tag">{{ statesLabes[version.state].label[lang] }}</fw-tag>
  </div>
</template>
<script>
import { VERSIONS_STATES } from '@/fw-modules/fw-core-vue/pages/utils/index.js'

export default {
  name: 'RecordVersion',
  props: {
    version: {
      type: Object,
      default: function() {
        return {}
      }
    },
    active: {
      type: Boolean,
      default: false
    },
    isDraft: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      statesLabes: Object.freeze(VERSIONS_STATES)
    }
  },
  computed: {
    title() {
      if (this.version?.title?.length) return this.version.title
      else if (this.version?.index > 0) return `v${this.version.index}`
      return 'Sem título'
    },
    lang() {
      return this.$store.state.language || this.$i18n.locale
    }
  }
}
</script>
